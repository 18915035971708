<template>
  <div>
  <q-pull-to-refresh :handler="getHappenings" refresh-icon="ion-sync" v-bind:release-message="$t('PTR.RELEASE')" v-bind:refresh-message="$t('PTR.REFRESH')" v-bind:pull-message="$t('PTR.PULL')" color="faded">
  <q-scroll-observable @scroll="scrolled"/>
  <q-page class="flex flex-center full-height">
    <div class="column">
      <div class="layout-padding no-padding-left no-padding-right" style="max-width: 700px; min-height: 100vh">
        <p class="text-center no-margin-bottom text-weight-semibold font-size-140p">Today</p>
        <p class="text-center caption uppercase text-weight-semibold text-shallow no-margin font-size-80p">{{ getCurrentDateInText() }}</p>
        <div class="row justify-between items-center margin-auto-lr">
          <div class="scrolling-wrapper-flexbox-row no-padding overflow-unset">
            <q-card inline style="min-width: 190px">
              <q-card-media overlay-position="bottom">
                <img src="https://lh3.googleusercontent.com/places/AJDFj43Ul-Qqeen4cuHdE-klgIO2lWd7kzSP_J0Jxn24D6oVwcrGI4Ods9ST5Lr_wbeyGSSsCPG37D3YMv-WJgyhsNyqkr5ti3OmfNw=s1600-w800"/>
                <q-card-title slot="overlay" class="text-system-brand">
                  SCM Cafe
                  <span slot="subtitle">473 Al Wasl Road - Dubai</span>
                </q-card-title>
              </q-card-media>
              <!-- q-card-main>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus, cupiditate animi? Dolores voluptas voluptate quos, quia eum error quam, placeat officia fugiat temporibus quae officiis illo optio voluptates sequi tempore?
              </q-card-main -->
              <q-card-actions>
                <q-btn color="primary" flat rounded label="OFFERS"/>
                <q-btn color="primary" flat rounded label="WI-FI"/>
                <q-btn color="empower" flat round right icon="ion-share"/>
              </q-card-actions>
            </q-card>
            <q-card inline style="min-width: 190px">
              <q-card-main>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus, cupiditate animi? Dolores voluptas voluptate quos, quia eum error quam, placeat officia fugiat temporibus quae officiis illo optio voluptates sequi tempore?
              </q-card-main>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-page>
  </q-pull-to-refresh>
  </div>
</template>

<script>
export default {
  name: 'PageToday',
  methods: {
    getHappenings (done) {
      setTimeout(done, 1000)
    },
    getCurrentDateInText () {
      return (new Date()).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })
    },
    scrolled (scroll) {
      // console.log(scroll)
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#appHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#appHeader').classList.add('no-shadow')
      }
    },
  }
}
</script>

<style lang="stylus">
.scrolling-wrapper-flexbox
  display flex
  flex-wrap nowrap
  overflow-x auto
  .q-card
    flex 0 0 auto
    margin-right 10px !important
    &:last-child
      margin-right 40px !important

.scrolling-wrapper-flexbox-row
  display flex
  flex-wrap wrap
  flex-direction row
  overflow-x auto
  .q-card
    flex 1 0 300px

body.touch
  .scrolling-wrapper-flexbox-row
    justify-content space-evenly
    .q-card
      flex 1 1 140px
      margin 4px

@media only screen and (max-device-width: $breakpoint-xs-max)
  body.touch
    .scrolling-wrapper-flexbox-row
      .q-card
        flex 1 1 120px

body.no-touch
  .scrolling-wrapper-flexbox-row
    // justify-content flex-start
    justify-content space-evenly
    .q-card
      // flex 0 1 240px
      flex 1 1 220px
      // flex 0 1 220px
      margin 8px
      margin-top 20px
  .scrolling-wrapper-flexbox-row-wide
    .q-card
      flex 1 0 340px
      border 2px solid red

.scrolling-wrapper-flexbox, .scrolling-wrapper-flexbox-row
  padding 20px
  // padding-left 2px
  // margin-top -20px
  margin-bottom 0px
  width 100%
  -webkit-overflow-scrolling touch
  overflow-scrolling touch
  &::-webkit-scrollbar
    display none
</style>