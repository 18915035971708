var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-pull-to-refresh",
        {
          attrs: {
            handler: _vm.getHappenings,
            "refresh-icon": "ion-sync",
            "release-message": _vm.$t("PTR.RELEASE"),
            "refresh-message": _vm.$t("PTR.REFRESH"),
            "pull-message": _vm.$t("PTR.PULL"),
            color: "faded"
          }
        },
        [
          _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
          _c("q-page", { staticClass: "flex flex-center full-height" }, [
            _c("div", { staticClass: "column" }, [
              _c(
                "div",
                {
                  staticClass:
                    "layout-padding no-padding-left no-padding-right",
                  staticStyle: { "max-width": "700px", "min-height": "100vh" }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center no-margin-bottom text-weight-semibold font-size-140p"
                    },
                    [_vm._v("Today")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center caption uppercase text-weight-semibold text-shallow no-margin font-size-80p"
                    },
                    [_vm._v(_vm._s(_vm.getCurrentDateInText()))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-between items-center margin-auto-lr"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "scrolling-wrapper-flexbox-row no-padding overflow-unset"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticStyle: { "min-width": "190px" },
                              attrs: { inline: "" }
                            },
                            [
                              _c(
                                "q-card-media",
                                { attrs: { "overlay-position": "bottom" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "https://lh3.googleusercontent.com/places/AJDFj43Ul-Qqeen4cuHdE-klgIO2lWd7kzSP_J0Jxn24D6oVwcrGI4Ods9ST5Lr_wbeyGSSsCPG37D3YMv-WJgyhsNyqkr5ti3OmfNw=s1600-w800"
                                    }
                                  }),
                                  _c(
                                    "q-card-title",
                                    {
                                      staticClass: "text-system-brand",
                                      attrs: { slot: "overlay" },
                                      slot: "overlay"
                                    },
                                    [
                                      _vm._v(
                                        "\n                SCM Cafe\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "subtitle" },
                                          slot: "subtitle"
                                        },
                                        [_vm._v("473 Al Wasl Road - Dubai")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-card-actions",
                                [
                                  _c("q-btn", {
                                    attrs: {
                                      color: "primary",
                                      flat: "",
                                      rounded: "",
                                      label: "OFFERS"
                                    }
                                  }),
                                  _c("q-btn", {
                                    attrs: {
                                      color: "primary",
                                      flat: "",
                                      rounded: "",
                                      label: "WI-FI"
                                    }
                                  }),
                                  _c("q-btn", {
                                    attrs: {
                                      color: "empower",
                                      flat: "",
                                      round: "",
                                      right: "",
                                      icon: "ion-share"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticStyle: { "min-width": "190px" },
                              attrs: { inline: "" }
                            },
                            [
                              _c("q-card-main", [
                                _vm._v(
                                  "\n              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus, cupiditate animi? Dolores voluptas voluptate quos, quia eum error quam, placeat officia fugiat temporibus quae officiis illo optio voluptates sequi tempore?\n            "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }